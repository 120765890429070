import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'

const Perspective = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  // if (!posts.length) {
  //   return (
  //     <Layout>
  //       <Seo title="Perspective | Photography blog by Chad Rieder" />
  //     </Layout>
  //   )
  // }

  return (
    <Layout>
      <Seo title="Chad Rieder | Perspective photography blog" description="A travel and photography blog by Minneapolis music and landscape photographer Chad Rieder." />
      <div className="archive-inner">

        <ul className="post-list" style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.title
            const featuredImage = {
              fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
              alt: post.featuredImage?.node?.alt || ``,
            }

            return (
              <li className="post-list-item" key={post.uri}>
                <ParallaxProvider>
                  <Link to={post.uri} itemProp="url">
                  
                    <article
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <Parallax y={[-20, 20]} tagOuter="figure">
                        {featuredImage?.fluid && (
                          <Image
                            fluid={featuredImage.fluid}
                            alt={featuredImage.alt}
                          />
                        )}
                      </Parallax>
          
                      <h1 itemProp="headline" className="preview-hdg">{parse(title)}</h1>
                    </article>
                    
                  </Link>
                </ParallaxProvider>
              </li>
            )
          })}
        </ul>
      </div>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default Perspective

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
